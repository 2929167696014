<template>
    <div :id="id" />
</template>

<script>
import wNumb from 'wnumb';
import noUiSlider from 'nouislider';
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        start: {
            type: [String, Number, Array, Object],
            default: 0,
        },
        min: {
            type: [String, Number],
            default: 0,
        },
        max: {
            type: [String, Number],
            default: 60,
        },
        step: {
            type: [String, Number],
            default: 5,
        },
        margin: {
            type: [String, Number],
            default: 5,
        },
        pips: {
            type: Object,
            default: () => ({
                mode: 'range',
                density: (100 / 30) * 5, // pip interval.
            }),
        },
        tooltips: {
            type: Array,
            default: () => [wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
        },
    },
    mounted() {
        noUiSlider.create(this.$el, {
            // Handle default values. Used in conjunction with 'tooltips' object
            start: this.start,

            // How many clicks the handle has to snap into place
            step: this.step,

            // How far apart each handle MUST be.
            margin: this.margin,

            // Low and high total range of slider
            range: {
                min: this.min,
                max: this.max,
            },

            pips: this.pips,

            // Array with a formatter for each handle. Used in conjunction with 'start' object
            tooltips: this.tooltips,

            format: wNumb({
                decimals: 0,
            }),
        });

        // Store range slider value on update
        this.$el.noUiSlider.on('change', (newRangeValues) => {
            this.$emit('update', newRangeValues);
        });
    },
};
</script>
